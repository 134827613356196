<template>
  <div class="WhoisView">
    <div class="o-container-gray">
      <h3 class="o-default-h3">{{ $t('whoisView') }} {{ $t('contactEmailInreg') }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoisView',
  data() {
    return {
      // msg: 'Welcome to Yosur Vue.js App',
    };
  },
};
</script>
